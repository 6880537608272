import axios from "axios";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { BreadcrumbItem, Card, CardHeader, Col, Row } from "reactstrap";

const SystemInfo = () => {
  const appName = process.env.REACT_APP_NAME;
  const [backendVersion, setBackendVersion] = useState("");
  const fetchBackendVersion = async () => {
    try {
      const resp = await axios.get(`${process.env.REACT_APP_API_URL}/api/version`);
      setBackendVersion(resp.data.version);
    } catch (error) {
      console.error("Failed to fetch backend version:", error);
      setBackendVersion("Error fetching version");
    }
  };
  useEffect(() => {
    fetchBackendVersion();
  }, []);

  return (
    <React.Fragment>
      <div className="p-5">
        <MetaTags>
          <title>System Info | {appName}</title>
        </MetaTags>
        <div className="container-fluid">
          <div className="header w-100">
            <h4 className="mb-0 font-size-18">System Info</h4>
          </div>
          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader className="cus-card">
                  <div className="row">
                    <div className="col-md-12 col-xl-7 order-xl-1">
                      <ol>
                        <li>Front-End: Version 1.0.0</li>
                        <li>Back-End: Version {backendVersion || "Loading..."}</li>
                      </ol>
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(SystemInfo);
