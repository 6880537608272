import React, { useState } from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { NavLink, Card, CardBody, CardHeader, CardTitle, Col, Container, Nav, NavItem, Row, TabContent, TabPane, CardText } from "reactstrap";
import classnames from "classnames";
import Grid from "src/Datatable";
import { Link } from "react-router-dom";
import moment from "moment";
import Details from "../Compliance/details";

const GSTRequest = () => {
  const [activeTab, setactiveTab] = useState("1");
  const [modal_is_open, set_modal_is_open] = useState(false);
  const [modalData, setModalData] = useState<any>();
  const openModal = (data: any) => {
    setModalData(data);
    set_modal_is_open(true);
  }
  const closeModal = () => {
    set_modal_is_open(false);
  }
  const toggle = (tab: any) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };
  const defaultSorted: any = [
    {
      dataField: "order_no",
      order: "desc",
    },
  ];
  const padLeadingZeros = (num: number, size: number) => {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  const colPeriod = (month: any, year: any, service: any) => {
    if (service == 1) {
        return `${months[(month - 1)]}-${year}`;
    } else if(service == 3) {
        return year;
    } else {
        return (<div>--</div>)
    }
}

  const arrStatus: Array<any> = [];
  arrStatus[1] = "Pending";
  arrStatus[2] = "Processing";
  arrStatus[3] = "Closed";
  arrStatus[4] = "On Hold";
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
      hidden: true
    },
    {
      dataField: "order_no",
      text: "Ticket ID",
      sort: true,
      formatter: (cellContent: any, row: any) => {
        return (
          <>
            RTN{padLeadingZeros(cellContent,5)}
          </>
        );
      }
    },
    {
      dataField: "payment_date",
      text: "Subscription Date",
      sort: true,
      formatter: (cellContent: any, row: any) => {
        return (
          <>
            {moment(cellContent).format("YYYY-MM-DD")}
          </>
        );
      }
    },
    {
      dataField: "service_name",
      text: "Service",
      sort: true,
      formatter: (cellContent: any, row: any) => {
        return (
          <>{cellContent}</>
        );
      }
    },
    {
      dataField: "month",
      text: "Period",
      sort: true,
      formatter: (cellContent: number, row: any) => {
        return (
          <>
            {colPeriod(row.month,row.year,row.service_id)}
          </>
        );
      }
    },
    {
      dataField: "company_name",
      text: "Company Name",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cellContent: number, row: any) => {
        if (cellContent == 1) {
          return (          
            <div className="badge badge-soft-info">{arrStatus[cellContent]}</div>
          );
        }
        else if (cellContent == 2) {
          return (          
            <div className="badge badge-soft-success">{arrStatus[cellContent]}</div>
          );
        } else if (cellContent == 4) {
          return (          
            <div className="badge badge-soft-dark">{arrStatus[cellContent]}</div>
          );
        } else {
          return (          
            <div className="badge badge-soft-danger">{arrStatus[cellContent]}</div>
          );
        }
      }
    },
    {
      dataField: "actions",
      text: "View",
      isDummyField: true,
      formatter: (cellContent: any, row: any) => {
        return (
          <div className='table-button'>
            <Link to="#" onClick={() => openModal(row)} className="btn tbl-btn mx-2">
              <i className="fas fa-eye"></i>
            </Link>
          </div>
        );
      }
    }
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>GST Request</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="GST Request" breadcrumbItem="GST Request" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        All
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        Processing
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          toggle("3");
                        }}
                      >
                        Closed
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab} className="p-3 text-muted">
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                        <Grid link="list_grid_all" columns={columns} defaultSorted={defaultSorted} refresh={modal_is_open} />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                        <Grid link="list_grid_progress" columns={columns} defaultSorted={defaultSorted} refresh={modal_is_open} />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12">
                        <Grid link="list_grid_completed" columns={columns} defaultSorted={defaultSorted} refresh={modal_is_open} />
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {modal_is_open && <Details modal_is_open={modal_is_open} closeModal={closeModal} data={modalData} />}
    </React.Fragment>
  );
};

export default GSTRequest;
