import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LoginTypes } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import {  
  postJwtLogin,  
  postJwtLoginOtp,
} from "../../../helpers/fakebackend_helper";
// import { initalData } from "src/store/sagas";

// const fireBaseBackend = getFirebaseBackend();

interface apiRes {
  success : boolean;
  message : string;
  data : JSON;
}

function* loginUser({ payload: { user, history } }: any) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response: apiRes = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      });
      if (response.success) {
        localStorage.setItem("authUser", JSON.stringify(response.data));
        yield put(loginSuccess(response));        
      } else {
        yield put(apiError(response.message));
      }
      
    } 
    history.push("/");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* loginUserOtp({ payload: { user, history } }: any) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response: apiRes = yield call(postJwtLoginOtp, {
        phone: user.phone,        
      });
      if (response.success) {
        localStorage.setItem("authUser", JSON.stringify(response.data));
        yield put(loginSuccess(response));        
      } else {
        yield put(apiError(response.message));
      }
      
    } 
    history.push("/invoices");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }: any) {
  try {
    localStorage.removeItem("authUser");

    history.push("/login"); 
  } catch (error) {
    yield put(apiError(error));
  }
}

function* loginSuccessUser({ payload: { history } }: any) {
  try {
    // yield call(initalData) 
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LoginTypes.LOGIN_USER, loginUser);  
  yield takeEvery(LoginTypes.LOGIN_USER_OTP, loginUserOtp);
  yield takeEvery(LoginTypes.LOGOUT_USER, logoutUser);
  yield takeEvery(LoginTypes.LOGIN_SUCCESS, loginSuccessUser);
}

export default authSaga;
