import React, {useEffect, useState } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Modal } from "reactstrap";
import { getAccessToken } from "src/helpers/api_helper";
import axios from "axios";

interface Props {
  modal_is_open: boolean;
  closeModal: any;
  data: any;
  onButtonClick: any;
}
const Details = (props: Props) => {
 
  const [errorMgs, setErrorMgs] = useState(
    "Somthing went wrong, Please try again"
  );
  const [isModalProgress, setIsModalProgress] = useState(false);
  const [CouponName, setCouponName] = useState<String>();
  const [CouponValue, setCouponValue] = useState<any>();
  const [IsPercentage, setIsPercentage] = useState<Boolean>(false);
  const [ExpireDate, setExpireDate] = useState<string>();

  const handleValidSubmit = async (event: any, values: any) => {
    setIsModalProgress(true);

    const userForm: any = document.getElementById("ticketForm");

    const couponData = {
        coupon_name: CouponName,
        coupon_value: parseFloat(CouponValue),
        is_percentage: IsPercentage,
        expiry_date: ExpireDate,
      };
      
    var token = getAccessToken();
    const resp: any = await axios.post(
      process.env.REACT_APP_API_URL + "/api/save_coupon",
      couponData,
      { headers: { Authorization: token } }
    );

    if (resp.data.success == true) {
      props.onButtonClick(true);
      props.closeModal();
    } else {
      setErrorMgs(resp.data.message);

    }
    setIsModalProgress(false);
  };


  return (
    <Modal isOpen={props.modal_is_open} className={"md-500"}>
      <AvForm
        onValidSubmit={handleValidSubmit}
        encType="multipart/formData"
        id="ticketForm"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
          Create Coupon
          </h5>
          <button
            type="button"
            onClick={() => props.closeModal()}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            id="closeButton"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="row m-2">
          <label
            htmlFor="progresspill-firstname-input"
            className="col-sm-4 col-form-label"
          >
            Coupon Name
          </label>
          <div className="col-sm-6">
            <AvField
              name="CouponName"
              type="text"
              placeholder="Enter Coupon Name"
              errorMessage="Enter Coupon Name"
              validate={{ required: { value: true } }}
              value={CouponName}
              onChange={(e: any) => setCouponName(String(e.target.value))}
            />
          </div>
        </div>
        <div className="row m-2">
          <label
            htmlFor="progresspill-firstname-input"
            className="col-sm-4 col-form-label"
          >
            Value
          </label>
          <div className="col-sm-6">
            <AvField
              name="CouponValue"
              type="number"
              placeholder="Enter Value"
              errorMessage="Enter Value"
              validate={{ required: { value: true } }}
              value={CouponValue}
              onChange={(e: any) => setCouponValue(Number(e.target.value))}
            />
          </div>
        </div>
        <div className="row m-2">
          <label
            htmlFor="progresspill-firstname-input"
            className="col-sm-4 col-form-label"
          >
            Is Percentage
          </label>
          <div className="col-sm-2">
            <AvField
              name="IsPercentage"
              type="checkbox"
              placeholder="Enter Is Percentage"
              errorMessage="Enter Is Percentage"
              onChange={(e: any) => setIsPercentage(Boolean(e.target.value))}
            />
          </div>
        </div>
        <div className="row m-2">
          <label
            htmlFor="progresspill-firstname-input"
            className="col-sm-4 col-form-label"
          >
            Expire Date
          </label>
          <div className="col-sm-6">
            <AvField
              name="ExpireDate"
              type="date"
              placeholder="Enter Expire Date"
              errorMessage="Enter  Date"
              validate={{ required: { value: true } }}
              value={ExpireDate}
              onChange={(e: any) => setExpireDate(String(e.target.value))}
            />
          </div>
        </div>

        <div className="modal-footer justify-content-center">
          {isModalProgress ? (
            <button type="submit" className="btn btn-primary " disabled={true}>
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
              Submiting
            </button>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-secondary "
                onClick={() => props.closeModal()}
              >
                Close
              </button>{" "}
              <button type="submit" className="btn btn-primary ">
                Save
              </button>
            </>
          )}
        </div>
      </AvForm>
    </Modal>
  );
};

export default Details;
