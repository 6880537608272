import React, { useEffect, useState } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Modal,
  Row,
  Table,
  UncontrolledAlert,
} from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { get, getAccessToken, post } from "src/helpers/api_helper";
import axios from "axios";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { isEmpty } from "lodash";

interface Props {
  modal_is_open: boolean;
  closeModal: any;
  data: any;
}
const Details = (props: Props) => {
  const [isHideSuccessMgs, setIsHideSuccessMgs] = useState(true);
  const [isHideErrorMgs, setIsHideErrorMgs] = useState(true);
  const [errorMgs, setErrorMgs] = useState(
    "Somthing went wrong, Please try again"
  );
  const [isModalProgress, setIsModalProgress] = useState(false);
  const [statusValue, setStatusValue] = useState(props.data.status);
  const [gstReturnId, setGstReturnId] = useState<number>(1);
  const [csvData, setCsvData] = useState<any>([]);
  const [pdfData, setPdfData] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [invoice_prefix, setInvoice_prefix] = useState<string>();
  const arrStatus: Array<any> = [];
  arrStatus[1] = "Pending";
  arrStatus[2] = "Processing";
  arrStatus[3] = "Closed";
  arrStatus[4] = "On Hold";
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const csvHeaders = [
    { label: "Invoice Date", key: "invoice_date" },
    { label: "Invoice Number", key: "invoice_no" },
    { label: "Customer Billing GSTIN", key: "gstin" },
    { label: "State Place of Supply", key: "place_state" },
    { label: "HSN/SAC", key: "hsn_or_sac" },
    { label: "Item Taxable Value", key: "taxableValue" },
    { label: "GST Tax Rate", key: "tax_per" },
    { label: "Total Transcation Value", key: "amount" },
  ];
  const pdfHeader = [
    [
      "Invoice Date",
      "Invoice Number",
      "Customer Billing GSTIN",
      "State Place of Supply",
      "HSN/SAC",
      "Item Taxable Value",
      "GST Tax Rate",
      "Total Transcation Value",
    ],
  ];
  const handleValidSubmit = async (event: any, values: any) => {
    setIsModalProgress(true);
    setIsHideSuccessMgs(true);
    setIsHideErrorMgs(true);
    const userForm: any = document.getElementById("ticketForm");
    const formData = new FormData(userForm);

    formData.append("id", props.data.id);
    var token = getAccessToken();
    const resp: any = await axios.post(
      process.env.REACT_APP_API_URL + "/api/save_ticket",
      formData,
      { headers: { Authorization: token } }
    );

    if (resp.data.success == true) {
      setIsHideSuccessMgs(false);
      props.closeModal();
    } else {
      setErrorMgs(resp.data.message);
      setIsHideErrorMgs(false);
    }
    setIsModalProgress(false);
  };
  const padLeadingZeros = (num: number, size: number) => {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  };
  const sendGetRequest = async () => {
    try {
      data["id"] = props.data.id;
      data["companyId"] = props.data.company_id;
      data["month"] = props.data.month;
      data["year"] = props.data.year;
      data["instance"] = props.data.instance_id;
      const resp = await post(
        process.env.REACT_APP_API_URL + "/api/sales_gstr1",
        data
      );
      if (resp.success) {
        setData(resp.data);
        setInvoice_prefix(resp.invoice_prefix);
        let csvData: any = [];
        let pdfData: any = [];
        resp.data.map((report: any) => {
          let taxableValue = report.qty * report.rate - report.discount_amt;
          csvData.push({
            invoice_date: moment(report.invoice_date).format("YYYY-MM-DD"),
            invoice_no: invoice_prefix
              ? invoice_prefix + padLeadingZeros(report.invoice_no, 5)
              : "INV" + padLeadingZeros(report.invoice_no, 5),
            gstin: report.gstin,
            place_state: report.place_state,
            hsn_or_sac: report.hsn_or_sac,
            taxableValue: taxableValue.toFixed(2),
            tax_per: `${report.tax_per}%`,
            amount: report.amount,
          });
          pdfData.push([
            moment(report.invoice_date).format("YYYY-MM-DD"),
            invoice_prefix
              ? invoice_prefix + padLeadingZeros(report.invoice_no, 5)
              : "INV" + padLeadingZeros(report.invoice_no, 5),
            report.gstin,
            report.place_state,
            report.hsn_or_sac,
            taxableValue.toFixed(2),
            `${report.tax_per}%`,
            report.amount,
          ]);
        });
        setCsvData(csvData);
        setPdfData(pdfData);
      }
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };
  useEffect(() => {
    if (props.data.service_id == gstReturnId) sendGetRequest();
  }, [props.data.id]);
  const downlaodPdf = () => {
    const doc: any = new jsPDF();
    autoTable(doc, {
      head: pdfHeader,
      body: pdfData,
    });
    doc.save("sales_gstr1_report.pdf");
  };
  const removeFile = async (id: string, index: number) => {
    const conf = window.confirm("Do you want to delete this file?");
    if (conf) {
      const resp: any = await post(
        process.env.REACT_APP_API_URL + "/api/gst_rm_file",
        { id, index }
      );
      if (resp.success == true) {
        let element: HTMLElement | null = document.getElementById(
          "file_" + index
        );
        element?.parentNode?.removeChild(element);
      } else {
        setIsHideErrorMgs(false);
      }
    }
  };
  return (
    <Modal isOpen={props.modal_is_open} className={"md-700"}>
      <AvForm
        onValidSubmit={handleValidSubmit}
        encType="multipart/formData"
        id="ticketForm"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Compliance Tickets Details
          </h5>
          <button
            type="button"
            onClick={() => props.closeModal()}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            id="closeButton"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <UncontrolledAlert hidden={isHideSuccessMgs} color="success">
            Ticket Details Saved successfully
          </UncontrolledAlert>

          <UncontrolledAlert hidden={isHideErrorMgs} color="danger">
            {errorMgs}
          </UncontrolledAlert>
          <Card>
            <CardHeader>
              <CardTitle className="mb-0">Details</CardTitle>
            </CardHeader>
            <CardBody>
              <div>
                <div className="pb-1">
                  <Row>
                    <Col lg={4}>
                      <Row>
                        <Col xl={12}>
                          <div>
                            <h5 className="font-size-14">Company Name</h5>
                          </div>
                        </Col>
                        <Col xl={12}>
                          <div className="text-muted">
                            <p className="mb-2">{props.data.company_name}</p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4}>
                      <Row>
                        <Col xl={12}>
                          <div>
                            <h5 className="font-size-15">Ticket No</h5>
                          </div>
                        </Col>
                        <Col xl={12}>
                          <div className="text-muted">
                            <p className="mb-2">
                              RTN{padLeadingZeros(props.data.order_no, 5)}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4}>
                      <Row>
                        <Col xl={12}>
                          <div>
                            <h5 className="font-size-15">FY- Month</h5>
                          </div>
                        </Col>
                        <Col xl={12}>
                          <div className="text-muted">
                            <p className="mb-2">{`${
                              months[props.data.month - 1]
                            }-${props.data.year}`}</p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Row>
                        <Col xl={12}>
                          <div>
                            <h5 className="font-size-14">GSTIN</h5>
                          </div>
                        </Col>
                        <Col xl={12}>
                          <div className="text-muted">
                            <p className="mb-2">{props.data.gstin}</p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4}>
                      <Row>
                        <Col xl={12}>
                          <div>
                            <h5 className="font-size-15">Email</h5>
                          </div>
                        </Col>
                        <Col xl={12}>
                          <div className="text-muted">
                            <p className="mb-2">{props.data.email}</p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4}>
                      <Row>
                        <Col xl={12}>
                          <div>
                            <h5 className="font-size-15">Phone</h5>
                          </div>
                        </Col>
                        <Col xl={12}>
                          <div className="text-muted">
                            <p className="mb-2">{props.data.phone}</p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <h5 className="font-size-14 my-2">Payment Details</h5>
                  <div className="table-responsive my-3">
                    <Table className="align-middle mb-0">
                      <thead>
                        <tr>
                          <th>Payment Date</th>
                          <th>Payment Id</th>
                          <th>Payment Amount</th>
                          {/* <th>Downloads</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {moment(props.data.payment_date).format(
                              "YYYY-MM-DD"
                            )}
                          </td>
                          <td>{props.data.razorpay_payment_id}</td>
                          <td>{props.data.payment_amount}</td>
                          {/* <td>
                                                        <div className='table-button'>
                                                            <CSVLink data={csvData} headers={csvHeaders} filename={"invoices.csv"} className="btn tbl-btn" >
                                                                <i className="fas fa-file-csv"></i>
                                                            </CSVLink>
                                                            <Link to="#" className="btn tbl-btn mx-2" style={{ color: "red" }} onClick={() => downlaodPdf()} >
                                                                <i className="fas fa-file-pdf"></i>
                                                            </Link>
                                                        </div>
                                                    </td> */}
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  {props.data.service_id == gstReturnId ? (
                    <>
                      <h5 className="font-size-14 my-2">Exports</h5>
                      <Row>
                        <Col lg={12}>
                          <div className="table-button my-2">
                            <CSVLink
                              data={csvData}
                              headers={csvHeaders}
                              filename={"sales_gstr1_report.csv"}
                              className="btn tbl-btn"
                            >
                              <i className="fas fa-file-csv"></i>
                            </CSVLink>
                            <Link
                              to="#"
                              className="btn tbl-btn mx-2"
                              style={{ color: "red" }}
                              onClick={() => downlaodPdf()}
                            >
                              <i className="fas fa-file-pdf"></i>
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}

                  <h5 className="font-size-14 my-3">
                    Upload Compliance Documents
                  </h5>
                  <Row>
                    <Col lg={4}>
                      <Row>
                        <Col xl={12}>
                          <div>
                            <h5 className="font-size-14">File 1</h5>
                          </div>
                        </Col>
                        <Col xl={12}>
                          <div className="text-muted">
                            <p className="mb-2">
                              <AvField
                                name="file1"
                                id="file1"
                                type="file"
                                placeholder="Choose File"
                                validate={{ required: { value: false } }}
                              />
                            </p>
                          </div>
                          {!isEmpty(props.data.file1) && (
                            <div className="table-button my-2" id="file_1">
                              <a
                                href={`data:application/pdf;base64,${props.data.file1Content}`}
                                download={props.data.file1}
                              >
                                {props.data.file_name1}
                              </a>{" "}
                              <Link
                                to="#"
                                onClick={() => removeFile(props.data.id, 1)}
                              >
                                <i
                                  className="fas fa-trash-alt"
                                  style={{ color: "red" }}
                                ></i>
                              </Link>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4}>
                      <Row>
                        <Col xl={12}>
                          <div>
                            <h5 className="font-size-14">File 2</h5>
                          </div>
                        </Col>
                        <Col xl={12}>
                          <div className="text-muted">
                            <p className="mb-2">
                              <AvField
                                name="file2"
                                id="file2"
                                type="file"
                                placeholder="Choose File"
                                validate={{ required: { value: false } }}
                              />
                            </p>
                          </div>
                          {!isEmpty(props.data.file2) && (
                            <div className="table-button my-2" id="file_2">
                              <a
                                href={`data:application/pdf;base64,${props.data.file2Content}`}
                                download={props.data.file2}
                              >
                                {props.data.file_name2}
                              </a>{" "}
                              <Link
                                to="#"
                                onClick={() => removeFile(props.data.id, 2)}
                              >
                                <i
                                  className="fas fa-trash-alt"
                                  style={{ color: "red" }}
                                ></i>
                              </Link>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4}>
                      <Row>
                        <Col xl={12}>
                          <div>
                            <h5 className="font-size-14">File 3</h5>
                          </div>
                        </Col>
                        <Col xl={12}>
                          <div className="text-muted">
                            <p className="mb-2">
                              <AvField
                                name="file3"
                                id="file3"
                                type="file"
                                placeholder="Choose File"
                                validate={{ required: { value: false } }}
                              />
                            </p>
                          </div>
                          {!isEmpty(props.data.file3) && (
                            <div className="table-button my-2" id="file_3">
                              <a
                                href={`data:application/pdf;base64,${props.data.file3Content}`}
                                download={props.data.file3}
                              >
                                {props.data.file_name3}
                              </a>{" "}
                              <Link
                                to="#"
                                onClick={() => removeFile(props.data.id, 3)}
                              >
                                <i
                                  className="fas fa-trash-alt"
                                  style={{ color: "red" }}
                                ></i>
                              </Link>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <h5 className="font-size-14 my-3">
                    Download Client Uploaded Documents
                  </h5>
                  <Row>
                    {!isEmpty(props.data.c_file1) && (
                      <Col lg={4}>
                        <div className="table-button mb-3">
                          <a
                            href={`data:application/pdf;base64,${props.data.c_file1Content}`}
                            download={props.data.c_file_name1}
                          >
                            {props.data.c_file_name1}{" "}
                            <i
                              className="fas fa-download"
                              aria-hidden="true"
                              style={{ color: "green" }}
                            ></i>
                          </a>
                        </div>
                      </Col>
                    )}
                    {!isEmpty(props.data.c_file2) && (
                      <Col lg={4}>
                        <div className="table-button mb-3">
                          <a
                            href={`data:application/pdf;base64,${props.data.c_file2Content}`}
                            download={props.data.c_file_name2}
                          >
                            {props.data.c_file_name2}{" "}
                            <i
                              className="fas fa-download"
                              aria-hidden="true"
                              style={{ color: "green" }}
                            ></i>
                          </a>
                        </div>
                      </Col>
                    )}
                    {!isEmpty(props.data.c_file3) && (
                      <Col lg={4}>
                        <div className="table-button mb-3">
                          <a
                            href={`data:application/pdf;base64,${props.data.c_file3Content}`}
                            download={props.data.c_file_name3}
                          >
                            {props.data.c_file_name3}{" "}
                            <i
                              className="fas fa-download"
                              aria-hidden="true"
                              style={{ color: "green" }}
                            ></i>
                          </a>
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Row>
                        <Col xl={12}>
                          <div>
                            <h5 className="font-size-14">Status</h5>
                          </div>
                        </Col>
                        <Col xl={12}>
                          <div className="text-muted">
                            <p className="mb-2">
                              <AvField
                                type="select"
                                name="status"
                                value={statusValue}
                                onChange={(e: any) =>
                                  setStatusValue(Number(e.target.value))
                                }
                              >
                                <option key={-1} value={""}>
                                  --Select--
                                </option>
                                {arrStatus.map(
                                  (status: string, key: number) => {
                                    return (
                                      <option value={key} key={key}>
                                        {status}
                                      </option>
                                    );
                                  }
                                )}
                              </AvField>
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="modal-footer justify-content-center">
          {isModalProgress ? (
            <button type="submit" className="btn btn-primary " disabled={true}>
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
              Submiting
            </button>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-dark"
                onClick={() => props.closeModal()}
              >
                Close
              </button>{" "}
              <button type="submit" className="btn btn-primary ">
                Save
              </button>
            </>
          )}
        </div>
      </AvForm>
    </Modal>
  );
};

export default Details;
